<template>
    <div>
        <template v-if='item.type === "material"'>
            <router-link :to="`/knowledge/article/${item.id}`">
                <div class="search-item">
                    <div
                        class="search-item__image"
                        :class="{ 'search-item__image--is-emoji': item.emoji }"
                    >
                        <div class="search-item__image-emoji">
                            <emoji
                                v-if="item.source.emoji"
                                :emoji="item.source.emoji"
                                set="apple"
                                :size="18"
                            />
                            <img v-else class="search-item__image-img" :src="icon" />
                        </div>
                    </div>
                    <div class="search-item__text">
                        <p
                            v-if="item.highlight.title || item.source.title"
                            class="search-item__text-title"
                            :class="{
                        'is-alone': !item.highlight.text
                    }"
                            v-html="`<span>${item.highlight.title || item.source.title}</span>`"
                        ></p>
                        <p
                            v-if="item.highlight.text || item.source.text"
                            class="search-item__text-subtitle"
                            :class="{
                        'is-alone': !item.highlight.title
                    }"
                            v-html="`<span>${item.highlight.text || item.source.text}</span>`"
                        ></p>
                        <p
                            v-if="item.source.file_name"
                            class="search-item__text-subtitle"
                            :class="{
                        'is-alone': !item.highlight.title
                    }"
                            v-html="`<span>${item.source.file_name}</span>`"
                        ></p>
                    </div>
                </div>
            </router-link>
        </template>
        <template v-if='item.type === "user"'>
            <router-link :to="`/auth/profile/${item.id}/?staff=1`">
                <div class="search-item">
                    <div
                        class="search-item__image"
                        :class="{ 'search-item__image--is-emoji': item.emoji }"
                    >
                        <div class="search-item__image-emoji">
                            <img  v-if="item.source.image" class="search-item__image-img user" :src='item.source.image' alt=''>
<!--                            <emoji-->
<!--                                v-if="item.source.image"-->
<!--                                :emoji="item.source.image"-->
<!--                                set="apple"-->
<!--                                :size="40"-->
<!--                            />-->
                            <img v-else class="search-item__image-img user" :src="icon" />
                        </div>
                    </div>
                    <div class="search-item__text">
                        <p class="search-item__text-title">
                            {{item.source.full_name}}
                        </p>
                        <p class='search-item__text-subtitle'> {{item.source.position}}</p>
                    </div>
                </div>
            </router-link>
        </template>
        <template v-if='item.type === "materialattachment"'>
            <router-link :to="`/knowledge/article/${item.source.material_id}`">
                <div class="search-item">
                    <div
                        class="search-item__image"
                        :class="{ 'search-item__image--is-emoji': item.emoji }"
                    >
                        <div class="search-item__image-emoji">
                            <emoji
                                v-if="item.source.emoji"
                                :emoji="item.source.emoji"
                                set="apple"
                                :size="18"
                            />
                            <img v-else class="search-item__image-img" :src="icon" />
                        </div>
                    </div>
                    <div class="search-item__text">
                        <p class="search-item__text-title"
                        >{{item.source.file_name}}</p>
                    </div>
                </div>
            </router-link>
        </template>
        <template v-if='item.type==="course"'>
            <router-link :to="`/courses/${item.id}`">
                <div class="search-item">
                    <div
                        class="search-item__image"
                        :class="{ 'search-item__image--is-emoji': item.emoji }"
                    >
                        <div class="search-item__image-emoji">
<!--                            <img  v-if="item.source.emoji" class="search-item__image-img user" :src='item.source.emoji' alt=''>-->
                            <emoji
                                v-if="item.source.emoji"
                                :emoji="item.source.emoji"
                                set="apple"
                                :size="18"
                            />
                            <img v-else class="search-item__image-img" :src="icon" />
                        </div>
                    </div>
                    <div class="search-item__text">
                        <p class="search-item__text-title"
                        >
                            {{item.source.name}}
                        </p>

                    </div>
                </div>
            </router-link>
        </template>
        <template v-if='item.type === "document"'>
            <button class="search-item__btn" @click="documentRedirect(item.source)">
                <div class="search-item">
                    <div
                        class="search-item__image"
                        :class="{ 'search-item__image--is-emoji': item.emoji }"
                    >
                        <div class="search-item__image-emoji">
                            <emoji
                                v-if="item.source.emoji"
                                :emoji="item.source.emoji"
                                set="apple"
                                :size="18"
                            />
                            <img v-else class="search-item__image-img" :src="icon" />
                        </div>
                    </div>
                    <div class="search-item__text">
                        <p class="search-item__text-title"
                        >
                            {{item.source.name}}
                        </p>

                    </div>
                </div>
            </button>
        </template>
        <!--     Статично!   -->

    </div>

</template>

<script>
    import { Emoji } from 'emoji-mart-vue'
    import Material from 'assets/img/favicons/material.svg'
    import Rubric from 'assets/img/favicons/rubric.svg'
    import defaultAvatar from '@/assets/img/avatars/default_avatar.jpg';
    import studying from  "@/assets/img/favicons/sidebar/studying.svg"
    import document from  "@/assets/img/documents.svg"
    export default {
        components: { Emoji },
        data() {
            return {
                Material,
                Rubric,
                defaultAvatar,
                studying,
                document
            }
        },
        computed: {
            icon() {
                if (this.item.type === 'material'){
                    return this.Material
                }
                if(this.item.type === 'user'){
                    return  this.defaultAvatar;
                }
                if (this.item.type === 'document' ||this.item.type ===  "materialattachment"){
                    return  this.document
                }
                if (this.item.type === 'course'){
                    return studying
                }
                return this.Rubric;
            }
        },
        props: {
            item: { type: Object, required: true }
        },
        methods: {
            documentRedirect (document) {
                const ABSOLUT_LINK = 1
                if (document.type === ABSOLUT_LINK) {
                    window.open(document.link)
                }
                else {
                    window.open(`${window.location.origin}${document.link}`)
                }
            }
        },
        mounted() {
            console.log(this.item)
        }
    }
</script>

<style lang="scss" scoped>
    $maxWidthIcons: 40px;
    .is-alone {
        -webkit-line-clamp: 2 !important;
    }
    .search-item {
        display: flex;
        align-items: center;
        padding: 14px 10px;
        border-radius: 8px;
        margin: 0 -10px;
        transition: background-color 200ms ease;
        &:hover {
            background: #e6ebf3;
        }
        &__image {
            width: $maxWidthIcons;
            justify-content: center;
            display: flex;
            align-items: center;
            &--is-emoji {
                align-items: flex-start;
            }
            &-emoji {
                padding-top: 6px;
                display: flex;
                align-items: center;
                align-self: flex-start;
            }
            &-img {
                width: 18px;
                height: 18px;
                &.user{
                    width: $maxWidthIcons;
                    height: $maxWidthIcons;
                    border-radius: 50%;
                }
            }
        }
        &__text {
            margin-left: 12px;
            &-title {
                color: #000;
                font-size: 20px;
                line-height: 28px;
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
            }
            &-subtitle {
                color: #9d9d9e;
                font-size: 14px;
                line-height: 20px;
                margin: 0;
                margin-top: 4px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
            }
        }
        &__btn {
            width: 100%;
            padding: 0;
            border: none;
            background-color: transparent;
            cursor: pointer;
        }
    }
</style>

<style>
    strong {
        color: #000;
    }
</style>
